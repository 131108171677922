<template>
  <div>
    <lottie-player
      src="https://assets1.lottiefiles.com/packages/lf20_uu0x8lqv.json"
      background="transparent"
      speed="1"
      class="mx-auto mt-12"
      style="width: 300px; height: 300px;"
      autoplay></lottie-player>
    <div>
      <div class="d-flex justify-center px-8">
        <p><span class="green--text">{{ $t('global.paidSuccess') }} </span>{{ $t('global.backToMain') }} <a :href="link">{{ $t('global.click') }}</a></p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    link () {
      return `${process.env.VUE_APP_FRONTEND_URL}/liff/history`
    }
  },
  mounted () {
    setTimeout(() => {
      this.$router.push('/liff/booking')
    }, 5000)
  }
}
</script>
